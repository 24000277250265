export const dropDownMenuNB = [
    {
        id: "0",
        title: "显示全部",
    },
    {
        id: "1",
        title: "今日开港",
    },
    {
        id: "2",
        title: "今日截港",
    },

    {
        id: "3",
        title: "今日截单/截关",
    },
    {
        id: "4",
        title: "今日抵港",
    },
    {
        id: "5",
        title: "今日离港",
    },
];

export const dropDownMenuSH = [
    {
        id: "0",
        title: "显示全部",
    },
    {
        id: "1",
        title: "今日开港",
    },
    {
        id: "2",
        title: "今日截港",
    },

    {
        id: "4",
        title: "今日抵港",
    },
    {
        id: "5",
        title: "今日离港",
    },
];


export const dropDownMenuQD = [
    {
        id: "0",
        title: "显示全部",
    },
];


export const dropDownMenuTJ=[
    {
        id: "0",
        title: "显示全部",
    },
]

export const dropDownMenuXM = [
    {
        id: "0",
        title: "显示全部",
    },
    {
        id: "1",
        title: "今日开港",
    },
    {
        id: "2",
        title: "今日截港",
    },

    {
        id: "3",
        title: "今日截单/截关",
    },
    {
        id: "4",
        title: "今日抵港",
    },
    {
        id: "5",
        title: "今日离港",
    },
];


export const dropDownMenuSZ = [
    {
        id: "0",
        title: "显示全部",
    },
    {
        id: "1",
        title: "今日开港",
    },
    {
        id: "2",
        title: "今日截港",
    },

    {
        id: "3",
        title: "今日截单/截关",
    },
    {
        id: "4",
        title: "今日抵港",
    },
    {
        id: "5",
        title: "今日离港",
    },
];